/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Smuggling Gang Operating in the English Channel Dismantled"), "\n", React.createElement(_components.h2, null, "Overview of the Smuggling Gang Dismantled in the English Channel"), "\n", React.createElement(_components.p, null, "According to a news article from BBC, a smuggling gang operating in the English Channel has been dismantled by German, French, and Belgian police. The gang consisted of Iraqi and Syrian nationals of Kurdish origin and was involved in smuggling migrants across the channel. They obtained boats from China via Turkey and operated from Germany. The gang had the capacity to send eight vessels to the UK every day, with each boat carrying up to 55 people, despite being designed for only 10. They charged between €1,000 and €3,000 for a place on the boat. The operation is linked to at least 55 crossings, and investigations into the financial and money laundering activities of the network are ongoing."), "\n", React.createElement(_components.p, null, "Reference:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/news/world-europe-68374806"
  }, "Germany: Small boat smuggling gang 'dismantled' by police, says Europol - BBC.com")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
